import { render } from "./PackageOfServices.vue?vue&type=template&id=0b282dc7"
import script from "./PackageOfServices.ts?vue&type=script&lang=ts"
export * from "./PackageOfServices.ts?vue&type=script&lang=ts"

import "./packageOfServices.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0b282dc7"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0b282dc7', script)) {
    api.reload('0b282dc7', script)
  }
  
  module.hot.accept("./PackageOfServices.vue?vue&type=template&id=0b282dc7", () => {
    api.rerender('0b282dc7', render)
  })

}

script.__file = "src/components/PackageOfServices/PackageOfServices.vue"

export default script